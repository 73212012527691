<template>
  <div>
    <van-nav-bar title="我的会员卡" left-arrow @click-left="onClickLeft" />
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      style="min-height: 100vh"
    >
      <div class="card">
        <div class="subscript" v-show="tabsList[active].sub">
          {{ tabsList[active].sub }}
        </div>
        <img v-show="tabsList[active].day" :src="tabsList[active].day" alt="" />
      </div>
      <div class="tabs">
        <div
          class="tabs-item"
          v-for="(item, index) in tabsList"
          :key="index"
          :class="{ active: active == index }"
          @click="selectTab(index, item.card_type)"
        >
          <span>{{ item.name }}</span>
          <div>{{ item.num }}</div>
        </div>
      </div>
      <van-empty description="暂无数据" v-if="showEmpty" />
      <van-list
        v-else
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        offset="50"
        :immediate-check="false"
      >
        <div class="list">
          <div
            class="list-item"
            v-for="(item, index) in cardInfo"
            :key="index"
            :class="{ activeGray: item.exchangeStr == '已使用' }"
          >
            <div class="left-content">
              <div class="title">
                {{ item.typeStr }}
                <div
                  class="sub"
                  :class="{ activeSub: item.exchangeStr == '已使用' }"
                >
                  {{ item.exchangeStr }}
                </div>
              </div>
              <div class="time">{{ item.add_time }}</div>
            </div>
            <div class="right-content">
              <div
                v-show="item.exchangeStr == '未使用'"
                @click="
                  $router.push({
                    path: '/cardInformation',
                    query: { id: item.id }
                  })
                "
              >
                查看
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { membershipCardDetails } from "./../../api/data";
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      finished: false,
      active: 0,
      showEmpty: false,
      page: 1,
      tabsList: [
        {
          name: "全部",
          num: 0,
          card_type: ""
        },
        {
          name: "月卡",
          num: 0,
          sub: "月卡VIP",
          day: "https://t.yhaocang.com/upload/sf_static/img/myCard/30day.png",
          card_type: 1
        },
        {
          name: "季卡",
          num: 0,
          sub: "季卡VIP",
          day: "https://t.yhaocang.com/upload/sf_static/img/myCard/90day.png",
          card_type: 2
        },
        {
          name: "年卡",
          num: 0,
          sub: "年卡VIP",
          day: "https://t.yhaocang.com/upload/sf_static/img/myCard/360day.png",
          card_type: 3
        }
      ],
      cardCount: {},
      cardInfo: [],
      card_type: ""
    };
  },
  created() {
    this.GetmembershipCardDetails();
  },
  computed: {
    newCardCount() {
      return this.cardCount;
    }
  },
  methods: {
    onLoad() {
      console.log("触发");
      this.GetmembershipCardDetails();
    },
    async onRefresh() {
      this.page = 1;
      this.loading = true;
      this.cardInfo = [];
      this.finished = false;
      await this.GetmembershipCardDetails();
      this.isLoading = false;
    },
    selectTab(index, card_type) {
      this.active = index;
      this.cardInfo = [];
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.card_type = card_type;
      this.GetmembershipCardDetails();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    async GetmembershipCardDetails() {
      let res = await membershipCardDetails({
        card_type: this.card_type,
        page: this.page
      });

      if (res.data.code == 200) {
        let cardCount = res.data.data.cardCount;
        // console.log(cardCount);
        console.log(this.tabsList[1]);
        this.tabsList[0].num = cardCount.total;
        this.tabsList[1].num = cardCount.month;
        this.tabsList[2].num = cardCount.season;
        this.tabsList[3].num = cardCount.year;
        this.cardInfo.push(...res.data.data.cardInfo);
        ++this.page;
        this.loading = false;
        if (this.cardInfo.length <= 0 && res.data.data.cardInfo.length <= 0) {
          this.showEmpty = true;
        } else {
          this.showEmpty = false;
        }
        if (this.cardInfo.length >= 0 && res.data.data.cardInfo.length <= 0) {
          this.finished = true;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.van-nav-bar .van-icon {
  color: #333333;
}
.card {
  width: 692px;
  height: 385px;
  margin: auto;
  display: block;
  margin-top: 18px;
  background: url("https://t.yhaocang.com/upload/sf_static/img/myCard/mycard.png")
    0 0 no-repeat;
  background-size: 100%;
  .subscript {
    width: 215px;
    height: 74px;
    font-size: 35px;
    font-weight: bold;
    color: #1f1f1f;
    background: url("https://t.yhaocang.com/upload/sf_static/img/myCard/jiaobaio.png")
      0 0 no-repeat;
    background-size: 100%;
    text-align: center;
    line-height: 74px;
    box-sizing: border-box;
    padding-left: 40px;
    margin-left: auto;
    margin-right: 6px;
  }
  img {
    width: 219px;
    margin-left: auto;
    display: block;
    padding-top: 140px;
    margin-right: 20px;
  }
}
.tabs {
  display: flex;
  justify-content: space-around;
  margin-top: 58px;
  .tabs-item :nth-child(1) {
    border-left: 0px;
    // color: red;
  }
  &-item {
    box-sizing: border-box;
    width: 25%;
    height: 94px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #d2d2d2;
    span {
      font-size: 33px;
      font-weight: 300;
      color: #333333;
      text-align: center;
      display: block;
    }
    div {
      width: 88px;
      height: 45px;
      //   background: #333333;
      border-radius: 23px;
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #8f8787;
      text-align: center;
      line-height: 45px;
      margin: 0 auto;
    }
  }
  .active {
    span {
      font-weight: 600;
      color: #333333;
    }
    div {
      background: #333333;
      color: #ffffff;
    }
  }
}
.list {
  margin-top: 56px;
  &-item {
    width: 679px;
    height: 154px;
    background: #fefaf2;
    border: 1px solid #fdd67a;
    border-radius: 14px;
    margin: 0 auto;
    margin-bottom: 28px;
    box-sizing: border-box;
    padding: 27px 19px;
    display: flex;
    .left-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 31px;
        font-weight: 600;
        color: #080808;
        position: relative;
        .sub {
          width: 90px;
          height: 31px;
          border: 1px solid #ff2b2b;
          border-radius: 16px 16px 16px 0px;
          font-size: 21px;
          font-weight: 400;
          color: #ff0000;
          line-height: 29px;
          text-align: center;
          position: absolute;
          top: -10px;
          right: -5px;
        }
      }
      .time {
        font-size: 25px;
        font-weight: 300;
        color: #999999;
      }
    }
    .right-content {
      width: 121px;
      height: 100%;
      margin-left: auto;
      display: flex;
      align-items: flex-end;
      div {
        width: 121px;
        height: 43px;
        background: #fbc034;
        border-radius: 22px;
        text-align: center;
        line-height: 43px;
        color: #ffffff;
      }
    }
  }
}
.activeGray {
  background: #f9fbfb;
  border: 0;
}
.activeSub {
  border: 1px solid #999999 !important;
  color: #999999 !important;
}
</style>
